import * as tslib_1 from "tslib";
import { EventBody } from './event-body.model';
var PageView = /** @class */ (function (_super) {
    tslib_1.__extends(PageView, _super);
    function PageView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PageView;
}(EventBody));
export { PageView };
