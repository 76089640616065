import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {PatSignInService} from "../../service/pat-sign-in.service";
import {NgxSpinnerService} from "ngx-spinner";
import {User} from "../../model/user.model";
import {MatSnackBar} from "@angular/material";
import {BaseComponent} from "../base.component";
import {takeUntil, tap} from "rxjs/operators";

@Component({
  selector: 'pat-magic-auth-redirect',
  templateUrl: './magic-auth-redirect.component.html',
  styleUrls: []
})
export class MagicAuthRedirectComponent extends BaseComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private patSignIn: PatSignInService,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParams.pipe(
      takeUntil(this.unsubscribe),
      tap(params => {
        let token = params['token'];
        if (token) {
          setTimeout(() => {
            this.spinner.show()
              .then(() => {
                this.handleMagicAuth(token);
              });
          }, 0.25);
        }
      })
    ).subscribe();
  }

  private handleMagicAuth(token) {
    this.patSignIn.authenticateFromMagicLink(token)
      .then((user: User) => {
        this.spinner.hide()
          .then(() => {
            console.log('redirect to: ', user.redirectUrl);
            this.router.navigateByUrl(user.redirectUrl);
          });
      })
      .catch((err) => {
        console.log(err);
        this.spinner.hide()
          .then(() => {
            this.snackBar.open('登入失敗，請重試或聯絡我們。', '', {
              duration: 5000,
              verticalPosition: 'top',
              horizontalPosition: 'right'
            });
          });
      });
  }
}
