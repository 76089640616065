import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this.profileUrl = 'api/auth/userProfile';
        this.publicUserProfileUrl = 'api/auth/publicUserProfiles';
    }
    UserService.prototype.getAllUserProfile = function () {
        var url = this.publicUserProfileUrl;
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-AUTH-PATERMATER-TOKEN': localStorage.getItem('authToken')
            })
        };
        return this.http.get(url, options);
    };
    UserService.prototype.getPublicUserProfile = function (userUrn) {
        var url = this.publicUserProfileUrl + '/' + userUrn;
        return this.http.get(url);
    };
    UserService.prototype.getUserProfile = function (userId) {
        var url = this.profileUrl + '/' + userId;
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-AUTH-PATERMATER-TOKEN': localStorage.getItem('authToken')
            })
        };
        return this.http.get(url, options);
    };
    UserService.prototype.updateUserProfile = function (userId, data) {
        var url = this.profileUrl + '/' + userId;
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-AUTH-PATERMATER-TOKEN': localStorage.getItem('authToken')
            })
        };
        return this.http.put(url, data, options);
    };
    UserService.prototype.uploadAvatar = function (data) {
        var url = this.profileUrl + "/uploadProfilePicture";
        var options = {
            headers: new HttpHeaders({
                'X-AUTH-PATERMATER-TOKEN': localStorage.getItem('authToken')
            })
        };
        var formData = new FormData();
        formData.append('avatarImage', data, data.name);
        return this.http.post(url, formData, options);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
