/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./nav.component";
import * as i5 from "@angular/router";
import * as i6 from "../../service/nav-search.service";
var styles_ForumNavComponent = [i0.styles];
var RenderType_ForumNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForumNavComponent, data: {} });
export { RenderType_ForumNavComponent as RenderType_ForumNavComponent };
function View_ForumNavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "d-flex align-items-center btn-header back-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-arrow-left"]], null, null, null, null, null))], null, null); }
export function View_ForumNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "header", [["class", "d-flex secondary top-nav-bar sticky-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "d-flex align-items-center btn-header hamburger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.burgerClicked.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-bars font-size-18"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForumNavComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "media"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(8, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "media-body d-flex align-self-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "input-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "input", [["formControlName", "title"], ["placeholder", "\u554F\u554F\u4E0A\u6709\u9AD8\u5802"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(14, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i1.ɵdid(16, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fas fa-search clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.search() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isBackEnabled(); _ck(_v, 4, 0, currVal_0); var currVal_8 = _co.form; _ck(_v, 6, 0, currVal_8); var currVal_16 = "title"; _ck(_v, 14, 0, currVal_16); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 8).ngClassValid; var currVal_6 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 16).ngClassValid; var currVal_14 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 11, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_ForumNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "askpm-forum-nav", [], null, null, null, View_ForumNavComponent_0, RenderType_ForumNavComponent)), i1.ɵdid(1, 245760, null, 0, i4.ForumNavComponent, [i3.FormBuilder, i5.Router, i2.Location, i6.NavSearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForumNavComponentNgFactory = i1.ɵccf("askpm-forum-nav", i4.ForumNavComponent, View_ForumNavComponent_Host_0, {}, { burgerClicked: "burgerClicked" }, []);
export { ForumNavComponentNgFactory as ForumNavComponentNgFactory };
