import {Component, OnInit} from '@angular/core';
import {LinkService} from '../../service/link.service';

@Component({
  selector: 'pat-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: []
})
export class NotFoundComponent implements OnInit {

  constructor(private linkService: LinkService) { }

  ngOnInit() {
    this.linkService.updateTag({rel: 'nofollow'});
  }
}
