import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {MagicAuthRedirectComponent} from './core/component/sign-in/magic-auth-redirect.component';
import {NotFoundComponent} from './core/component/not-found/not-found.component';
import {AuthGuard} from './core/service/auth.guard';
import {HelpCenterComponent} from './core/component/help-center/help-center.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: './forum/forum.module#ForumModule'
  },
  {
    path: 'auth',
    component: MagicAuthRedirectComponent
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'help',
    component: HelpCenterComponent
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: './ps/ps.module#PsModule'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
