/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-profile-simplified.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user-profile-simplified.component";
import * as i3 from "../../service/user.service";
var styles_UserProfileSimplifiedComponent = [i0.styles];
var RenderType_UserProfileSimplifiedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserProfileSimplifiedComponent, data: {} });
export { RenderType_UserProfileSimplifiedComponent as RenderType_UserProfileSimplifiedComponent };
export function View_UserProfileSimplifiedComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "media"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "d-flex align-self-center mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "avatar rounded-circle"], ["data-v-5c6caf12", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "media-body align-self-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "username"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "font-pixel"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getAvatar(_co.user); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.user == null) ? null : _co.user.username); _ck(_v, 6, 0, currVal_1); }); }
export function View_UserProfileSimplifiedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-profile-simplified", [], null, null, null, View_UserProfileSimplifiedComponent_0, RenderType_UserProfileSimplifiedComponent)), i1.ɵdid(1, 245760, null, 0, i2.UserProfileSimplifiedComponent, [i3.UserService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserProfileSimplifiedComponentNgFactory = i1.ɵccf("user-profile-simplified", i2.UserProfileSimplifiedComponent, View_UserProfileSimplifiedComponent_Host_0, { userUrn: "user-urn", userProfile: "userProfile" }, {}, []);
export { UserProfileSimplifiedComponentNgFactory as UserProfileSimplifiedComponentNgFactory };
