import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'sign-in-statement-privacy',
  templateUrl: './statement-privacy.component.html'
})
export class SignInStatementPrivacyComponent {

  constructor(private dialogRef: MatDialogRef<SignInStatementPrivacyComponent>) {
  }

  close() {
    this.dialogRef.close();
  }
}
