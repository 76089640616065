import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TrackingEvent} from '../model/tracking/tracking-event.model';
import {PageView} from '../model/tracking/page-view.model';
import {ProblemPosted} from '../model/tracking/forum/problem-posted.model';
import {Observable} from 'rxjs';
import {SolutionPosted} from '../model/tracking/forum/solution-posted.model';
import {SolutionVoted} from '../model/tracking/forum/solution-voted.model';
import {Interaction} from '../model/tracking/interaction.model';

@Injectable({
  providedIn: 'root'
})
export class TrackingApiService {
  private readonly TRACKING_ENDPOINT = 'api/tracking/track';

  constructor(private http: HttpClient) {}

  createPageViewEvent(data: TrackingEvent<PageView>): Observable<string> {
    const options: object = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      responseType: 'text'
    };
    return this.http.post<string>(`${this.TRACKING_ENDPOINT}/pageViewEvent`, data, options);
  }

  createProblemPostedEvent(data: TrackingEvent<ProblemPosted>) {
    const options: object = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      responseType: 'text'
    };
    return this.http.post(`${this.TRACKING_ENDPOINT}/problemPostedEvent`, data, options);
  }

  createSolutionPostedEvent(data: TrackingEvent<SolutionPosted>) {
    const options: object = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      responseType: 'text'
    };
    return this.http.post(`${this.TRACKING_ENDPOINT}/solutionPostedEvent`, data, options);
  }

  createSolutionVotedEvent(data: TrackingEvent<SolutionVoted>) {
    const options: object = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      responseType: 'text'
    };
    return this.http.post(`${this.TRACKING_ENDPOINT}/solutionVotedEvent`, data, options);
  }

  createInteractionEvent(data: TrackingEvent<Interaction>) {
    const options: object = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      responseType: 'text'
    };
    return this.http.post(`${this.TRACKING_ENDPOINT}/interactionEvent`, data, options);
  }
}
