import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppContextService {
  private schema$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  getSchemaUpdate(): Observable<any> {
    return this.schema$.asObservable();
  }

  updateSchema(schema: any) {
    this.schema$.next(schema);
  }
}
