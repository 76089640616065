import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PatSignInService } from "../../service/pat-sign-in.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from "@angular/material";
import { BaseComponent } from "../base.component";
import { takeUntil, tap } from "rxjs/operators";
var MagicAuthRedirectComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MagicAuthRedirectComponent, _super);
    function MagicAuthRedirectComponent(router, route, patSignIn, spinner, snackBar) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.route = route;
        _this.patSignIn = patSignIn;
        _this.spinner = spinner;
        _this.snackBar = snackBar;
        return _this;
    }
    MagicAuthRedirectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.pipe(takeUntil(this.unsubscribe), tap(function (params) {
            var token = params['token'];
            if (token) {
                setTimeout(function () {
                    _this.spinner.show()
                        .then(function () {
                        _this.handleMagicAuth(token);
                    });
                }, 0.25);
            }
        })).subscribe();
    };
    MagicAuthRedirectComponent.prototype.handleMagicAuth = function (token) {
        var _this = this;
        this.patSignIn.authenticateFromMagicLink(token)
            .then(function (user) {
            _this.spinner.hide()
                .then(function () {
                console.log('redirect to: ', user.redirectUrl);
                _this.router.navigateByUrl(user.redirectUrl);
            });
        })
            .catch(function (err) {
            console.log(err);
            _this.spinner.hide()
                .then(function () {
                _this.snackBar.open('登入失敗，請重試或聯絡我們。', '', {
                    duration: 5000,
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                });
            });
        });
    };
    return MagicAuthRedirectComponent;
}(BaseComponent));
export { MagicAuthRedirectComponent };
