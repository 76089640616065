import {Deserializable} from './deserializable.model';

export class User implements Deserializable {
  userUrn: string;
  redirectUrl: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
