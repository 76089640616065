export class Utils {
  static isEmpty(object: any): boolean {
    if (object === undefined || object === null) {
      return true;
    }

    if (object === '' || object === {} ||
      (object instanceof Array && object.length === 0) ||
      (object instanceof Map && object.size === 0)) {
      return true;
    }

    return false;
  }
}
