import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/internal/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(http) {
        this.http = http;
        this.loginUrl = 'api/auth/login';
        this.magicLinkUrl = 'api/auth/magicAuth';
    }
    AuthService.prototype.login = function (credential) {
        var url = this.loginUrl;
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'text'
        };
        return this.http.post(url, credential, options)
            .pipe(tap(function (response) {
            localStorage.setItem('authToken', response);
        }));
    };
    AuthService.prototype.magicLinkSignIn = function (data) {
        var url = this.magicLinkUrl;
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(url, data, options);
    };
    AuthService.prototype.authenticateMagicToken = function (token) {
        var url = this.magicLinkUrl + '?token=' + token;
        var options = {
            responseType: 'text'
        };
        return this.http.get(url, options)
            .pipe(tap(function (response) {
            console.log(response);
            localStorage.setItem('authToken', response);
        }));
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
