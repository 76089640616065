import {Injectable, NgZone} from '@angular/core';
import {AuthService} from './auth.service';
import {tap} from 'rxjs/internal/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {User} from '../model/user.model';
import * as jwtDecode from 'jwt-decode';
import {Utils} from '../util/Utils';

const UNSIGNED_IN = 'UNSIGNED_IN';
const SIGNING_IN = 'SIGNING_IN';
const SIGNED_IN = 'SIGNED_IN';

@Injectable({
  providedIn: 'root'
})
export class PatSignInService {
  public auth2: any;
  public user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public signInState$: BehaviorSubject<string> = new BehaviorSubject<string>(UNSIGNED_IN);
  public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _signInState;

  constructor(private zone: NgZone,
              private authService: AuthService) {
    this.loadAuth2();
    this.initUser();
  }

  initUser() {
    if (localStorage.getItem('authToken')) {
      this.initUserIfSignedIn();
      this._signInState = SIGNED_IN;
      this.signInState$.next(SIGNED_IN);
    }
  }

  signInWithMagicLink(data: any) {
    return this.authService.magicLinkSignIn(data);
  }

  authenticateFromMagicLink(token: string) {
    return new Promise((resolve, reject) => {
      this.authService.authenticateMagicToken(token)
        .subscribe(() => {
          this.initUserIfSignedIn();
          this._signInState = SIGNED_IN;
          this.signInState$.next(SIGNED_IN);
          resolve(this.user$.getValue());
        }, error => {
          reject(error);
        });
    });
  }

  signInWithGoogle(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.auth2.signIn({
        prompt: 'select_account',
        scope: 'email profile'
      }).then((googleUser) => {
        this._signInState = SIGNING_IN;
        this.signInState$.next(SIGNING_IN);
        const response = googleUser.getAuthResponse();
        return this.signInToPatWithGoogle(response.id_token, googleUser.getBasicProfile().getEmail())
          .subscribe(res => resolve(res), err => reject(err));
      });
    });
  }

  signOut() {
    return new Promise((resolve) => {
      localStorage.removeItem('authToken');
      this._signInState = UNSIGNED_IN;
      this.signInState$.next(UNSIGNED_IN);
      this.user$.next(null);
      this.auth2.signOut();
      resolve(UNSIGNED_IN);
    });
  }

  loadAuth2(): void {
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: environment.googleOAuthClientId,
        fetch_basic_profile: true
      }).then((auth) => {
          this.zone.run(() => {
            this.auth2 = auth;
            this.isLoaded$.next(true);
          });
        },
      );
    });
  }

  checkSignInStatus(): boolean {
    if (this._signInState === UNSIGNED_IN) {
      return false;
    }
    if (this._signInState === SIGNED_IN && Utils.isEmpty(localStorage.getItem('authToken'))) {
      return false;
    }
    const exp = Number(this.decodeAuthToken(localStorage.getItem('authToken')).exp) * 1000;
    if (isNaN(exp) || exp < Date.now()) {
      return false;
    }
    return true;
  }

  private signInToPatWithGoogle(idToken: string, email: string): Observable<string> {
    const patCredential = {
      idToken,
      email,
      loginPayloadType: 'GOOGLE'
    };
    return this.authService.login(patCredential)
      .pipe(
        tap(() => {
          this.initUserIfSignedIn();
          this._signInState = SIGNED_IN;
          this.signInState$.next(SIGNED_IN);
        }, err => {
          this._signInState = UNSIGNED_IN;
          this.signInState$.next(UNSIGNED_IN);
        })
      );
  }

  private initUserIfSignedIn() {
    const token = localStorage.getItem('authToken');
    const decoded = this.decodeAuthToken(token);
    const user = JSON.parse(decoded.user);
    this.user$.next(new User().deserialize(user));
  }

  private decodeAuthToken(token: string): any {
    return jwtDecode(token);
  }
}
