import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LinkService } from './core/service/link.service';
import { environment } from '../environments/environment.prod';
import { PatSignInService } from './core/service/pat-sign-in.service';
import { TrackingApiService } from './core/service/tracking-api.service';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from './core/component/base.component';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Utils } from './core/util/Utils';
import { TrackingEvent } from './core/model/tracking/tracking-event.model';
import { PageView } from './core/model/tracking/page-view.model';
import { EventInfo } from './core/model/tracking/event-info.model';
import { SignInDialogComponent } from './core/component/sign-in/sign-in-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AppContextService } from './app.context.service';
var AppComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AppComponent, _super);
    function AppComponent(linkService, patSignInService, trackingApiService, router, dialog, snackBar, appContextService) {
        var _this = _super.call(this) || this;
        _this.linkService = linkService;
        _this.patSignInService = patSignInService;
        _this.trackingApiService = trackingApiService;
        _this.router = router;
        _this.dialog = dialog;
        _this.snackBar = snackBar;
        _this.appContextService = appContextService;
        _this.schema = {
            '@context': 'http://schema.org',
            '@type': 'Organization',
            url: environment.host,
            name: 'PaterMater',
            alternateName: '上有高堂',
            contactPoint: {
                '@type': 'ContactPoint',
                email: 'hello@patermater.org',
                contactType: 'Customer service'
            }
        };
        _this.signInState = 'UNSIGNED_IN';
        _this.isSignedIn = false;
        linkService.startRouteListener();
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.patSignInService.user$.pipe(takeUntil(this.unsubscribe), filter(function (user) { return !Utils.isEmpty(user); }), tap(function (user) { return _this.userUrn = user.userUrn; })).subscribe();
        this.patSignInService.signInState$.pipe(takeUntil(this.unsubscribe), tap(function (signInState) {
            _this.signInState = signInState;
            _this.isSignedIn = 'SIGNED_IN' === _this.signInState;
        })).subscribe();
        this.router.events.pipe(takeUntil(this.unsubscribe), filter(function (e) { return e instanceof NavigationEnd; }), switchMap(function (e) {
            gtag('config', 'G-QKY2LNQW2S', { page_path: e.urlAfterRedirects });
            return _this.trackingApiService.createPageViewEvent(_this.buildPageViewEvent(e.url));
        })).subscribe();
        this.appContextService.getSchemaUpdate().pipe(takeUntil(this.unsubscribe), filter(function (schema) { return !Utils.isEmpty(schema); }), tap(function (schema) { return _this.schema = schema; })).subscribe();
        gtag('get', 'G-QKY2LNQW2S', 'client_id', function (client_id) {
            _this.gaClientId = client_id;
        });
    };
    AppComponent.prototype.login = function () {
        this.openSignIn();
    };
    AppComponent.prototype.logout = function () {
        var _this = this;
        this.patSignInService.signOut().then(function () {
            _this.router.navigateByUrl('')
                .then(function () {
                location.reload();
            });
        });
    };
    AppComponent.prototype.openSignIn = function () {
        var _this = this;
        var dialogRef = this.dialog.open(SignInDialogComponent, {
            width: '600px',
            closeOnNavigation: true,
            hasBackdrop: true,
            autoFocus: false,
            data: {
                redirectUrl: location.pathname
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === 'SIGNED_IN') {
                _this.snackBar.open('登入成功！', '', {
                    duration: 3000,
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                });
            }
        });
    };
    AppComponent.prototype.buildPageViewEvent = function (pageKey) {
        var pageViewEvent = new TrackingEvent();
        var eventInfo = new EventInfo();
        var pageView = new PageView();
        eventInfo.application = 'askpm';
        eventInfo.eventName = 'PageViewEvent';
        eventInfo.topic = 'view';
        pageView.gaClientId = this.gaClientId;
        pageView.pageKey = pageKey;
        pageView.userUrn = this.userUrn;
        pageView.clientTimestamp = Date.now();
        pageViewEvent.eventInfo = eventInfo;
        pageViewEvent.eventBody = pageView;
        return pageViewEvent;
    };
    return AppComponent;
}(BaseComponent));
export { AppComponent };
