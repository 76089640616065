/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./not-found.component";
import * as i4 from "../../service/link.service";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "text-center mt-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u627E\u4E0D\u5230\u7DB2\u9801"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [["class", "py-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["height", "200"], ["src", "../../../../assets/img/404.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "a", [["routerLink", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["\u8FD4\u56DE\u4E3B\u9801"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "a", [["href", "mailto:hello@patermater.org"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u806F\u7D61\u6211\u5011"])), (_l()(), i0.ɵted(-1, null, [" \u6216\u5230 "])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "a", [["href", "https://www.facebook.com/PaterMaterHK"], ["target", "_blank"], ["title", "find us on Facebook"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "img", [["alt", "follow me on facebook"], ["border", "0"], ["src", "//login.create.net/images/icons/user/facebook_40x40.png"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u67E5\u8A62 "]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 8).target; var currVal_1 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_0, currVal_1); }); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pat-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i3.NotFoundComponent, [i4.LinkService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i0.ɵccf("pat-not-found", i3.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
