import { NgZone } from '@angular/core';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/internal/operators';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../model/user.model';
import * as jwtDecode from 'jwt-decode';
import { Utils } from '../util/Utils';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
var UNSIGNED_IN = 'UNSIGNED_IN';
var SIGNING_IN = 'SIGNING_IN';
var SIGNED_IN = 'SIGNED_IN';
var PatSignInService = /** @class */ (function () {
    function PatSignInService(zone, authService) {
        this.zone = zone;
        this.authService = authService;
        this.user$ = new BehaviorSubject(null);
        this.signInState$ = new BehaviorSubject(UNSIGNED_IN);
        this.isLoaded$ = new BehaviorSubject(false);
        this.loadAuth2();
        this.initUser();
    }
    PatSignInService.prototype.initUser = function () {
        if (localStorage.getItem('authToken')) {
            this.initUserIfSignedIn();
            this._signInState = SIGNED_IN;
            this.signInState$.next(SIGNED_IN);
        }
    };
    PatSignInService.prototype.signInWithMagicLink = function (data) {
        return this.authService.magicLinkSignIn(data);
    };
    PatSignInService.prototype.authenticateFromMagicLink = function (token) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.authService.authenticateMagicToken(token)
                .subscribe(function () {
                _this.initUserIfSignedIn();
                _this._signInState = SIGNED_IN;
                _this.signInState$.next(SIGNED_IN);
                resolve(_this.user$.getValue());
            }, function (error) {
                reject(error);
            });
        });
    };
    PatSignInService.prototype.signInWithGoogle = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.auth2.signIn({
                prompt: 'select_account',
                scope: 'email profile'
            }).then(function (googleUser) {
                _this._signInState = SIGNING_IN;
                _this.signInState$.next(SIGNING_IN);
                var response = googleUser.getAuthResponse();
                return _this.signInToPatWithGoogle(response.id_token, googleUser.getBasicProfile().getEmail())
                    .subscribe(function (res) { return resolve(res); }, function (err) { return reject(err); });
            });
        });
    };
    PatSignInService.prototype.signOut = function () {
        var _this = this;
        return new Promise(function (resolve) {
            localStorage.removeItem('authToken');
            _this._signInState = UNSIGNED_IN;
            _this.signInState$.next(UNSIGNED_IN);
            _this.user$.next(null);
            _this.auth2.signOut();
            resolve(UNSIGNED_IN);
        });
    };
    PatSignInService.prototype.loadAuth2 = function () {
        var _this = this;
        gapi.load('auth2', function () {
            gapi.auth2.init({
                client_id: environment.googleOAuthClientId,
                fetch_basic_profile: true
            }).then(function (auth) {
                _this.zone.run(function () {
                    _this.auth2 = auth;
                    _this.isLoaded$.next(true);
                });
            });
        });
    };
    PatSignInService.prototype.checkSignInStatus = function () {
        if (this._signInState === UNSIGNED_IN) {
            return false;
        }
        if (this._signInState === SIGNED_IN && Utils.isEmpty(localStorage.getItem('authToken'))) {
            return false;
        }
        var exp = Number(this.decodeAuthToken(localStorage.getItem('authToken')).exp) * 1000;
        if (isNaN(exp) || exp < Date.now()) {
            return false;
        }
        return true;
    };
    PatSignInService.prototype.signInToPatWithGoogle = function (idToken, email) {
        var _this = this;
        var patCredential = {
            idToken: idToken,
            email: email,
            loginPayloadType: 'GOOGLE'
        };
        return this.authService.login(patCredential)
            .pipe(tap(function () {
            _this.initUserIfSignedIn();
            _this._signInState = SIGNED_IN;
            _this.signInState$.next(SIGNED_IN);
        }, function (err) {
            _this._signInState = UNSIGNED_IN;
            _this.signInState$.next(UNSIGNED_IN);
        }));
    };
    PatSignInService.prototype.initUserIfSignedIn = function () {
        var token = localStorage.getItem('authToken');
        var decoded = this.decodeAuthToken(token);
        var user = JSON.parse(decoded.user);
        this.user$.next(new User().deserialize(user));
    };
    PatSignInService.prototype.decodeAuthToken = function (token) {
        return jwtDecode(token);
    };
    PatSignInService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PatSignInService_Factory() { return new PatSignInService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.AuthService)); }, token: PatSignInService, providedIn: "root" });
    return PatSignInService;
}());
export { PatSignInService };
