/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i2 from "ngx-spinner";
import * as i3 from "./magic-auth-redirect.component";
import * as i4 from "@angular/router";
import * as i5 from "../../service/pat-sign-in.service";
import * as i6 from "@angular/material/snack-bar";
var styles_MagicAuthRedirectComponent = [];
var RenderType_MagicAuthRedirectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MagicAuthRedirectComponent, data: {} });
export { RenderType_MagicAuthRedirectComponent as RenderType_MagicAuthRedirectComponent };
export function View_MagicAuthRedirectComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ngx-spinner", [], null, null, null, i1.View_NgxSpinnerComponent_0, i1.RenderType_NgxSpinnerComponent)), i0.ɵdid(1, 770048, null, 0, i2.NgxSpinnerComponent, [i2.NgxSpinnerService, i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "p", [["class", "white"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u767B\u5165\u4E2D\u22EF\u22EF"]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_MagicAuthRedirectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pat-magic-auth-redirect", [], null, null, null, View_MagicAuthRedirectComponent_0, RenderType_MagicAuthRedirectComponent)), i0.ɵdid(1, 245760, null, 0, i3.MagicAuthRedirectComponent, [i4.Router, i4.ActivatedRoute, i5.PatSignInService, i2.NgxSpinnerService, i6.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MagicAuthRedirectComponentNgFactory = i0.ɵccf("pat-magic-auth-redirect", i3.MagicAuthRedirectComponent, View_MagicAuthRedirectComponent_Host_0, {}, {}, []);
export { MagicAuthRedirectComponentNgFactory as MagicAuthRedirectComponentNgFactory };
