import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { Utils } from '../../util/Utils';
import { BehaviorSubject } from 'rxjs';
import { PublicUserProfile } from './public-user-profile.model';
var AbstractUserProfileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AbstractUserProfileComponent, _super);
    function AbstractUserProfileComponent() {
        var _this = _super.call(this) || this;
        _this.userUrn$ = new BehaviorSubject('');
        return _this;
    }
    Object.defineProperty(AbstractUserProfileComponent.prototype, "userUrn", {
        set: function (userUrn) {
            if (!Utils.isEmpty(userUrn)) {
                this.userUrn$.next(userUrn);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractUserProfileComponent.prototype, "userProfile", {
        set: function (userProfile) {
            if (!Utils.isEmpty(userProfile)) {
                this.user = userProfile;
            }
        },
        enumerable: true,
        configurable: true
    });
    AbstractUserProfileComponent.prototype.ngOnInit = function () {
        this._onInit();
    };
    return AbstractUserProfileComponent;
}(BaseComponent));
export { AbstractUserProfileComponent };
