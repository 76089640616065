import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormControl } from '@angular/forms';
import { Utils } from '../../util/Utils';
import { NavSearchService } from '../../service/nav-search.service';
import { FilterModel } from '../../model/filter.model';
var ForumNavComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ForumNavComponent, _super);
    function ForumNavComponent(fb, router, location, navSearchService) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.router = router;
        _this.location = location;
        _this.navSearchService = navSearchService;
        _this.burgerClicked = new EventEmitter();
        _this.currentSearchKeyword = '';
        _this.initForm();
        return _this;
    }
    ForumNavComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            title: new FormControl('', [])
        });
    };
    ForumNavComponent.prototype.ngOnInit = function () {
    };
    ForumNavComponent.prototype.search = function () {
        var _this = this;
        var value = this.form.get('title').value;
        if (!Utils.isEmpty(value) && this.currentSearchKeyword !== value) {
            this.currentSearchKeyword = value;
            var filterModel = new FilterModel();
            filterModel.title = value;
            this.navSearchService.updateFilterModel(filterModel);
            this.router.navigate(['searchResult']).then(function () {
                _this.form.reset();
                _this.currentSearchKeyword = '';
            });
        }
    };
    ForumNavComponent.prototype.isBackEnabled = function () {
        return this.router.url.includes('/q/') || this.router.url.includes('/searchResult');
    };
    ForumNavComponent.prototype.goBack = function () {
        this.router.navigate(['']);
    };
    return ForumNavComponent;
}(BaseComponent));
export { ForumNavComponent };
