import { Observable, throwError, timer } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { PatSignInService } from './pat-sign-in.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./pat-sign-in.service";
import * as i3 from "@angular/material/snack-bar";
var ErrorInterceptor = /** @class */ (function () {
    function ErrorInterceptor(router, patSignIn, snackBar) {
        var _this = this;
        this.router = router;
        this.patSignIn = patSignIn;
        this.snackBar = snackBar;
        this.patSignIn.signInState$.subscribe(function (state) { return _this._signInState = state; });
    }
    ErrorInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request)
            .pipe(
        // retryWhen(errors => {
        //   return errors.pipe(
        //     mergeMap(this.retryOnInternalServerError),
        //     take(environment.httpRetryCount)
        //   );
        // }),
        catchError(function (err) {
            switch (err.status) {
                case 401:
                    _this.handleUnauthorized();
                    break;
                default:
                    _this.checkIfUnauthorized();
                    break;
            }
            return throwError(err);
        }));
    };
    ErrorInterceptor.prototype.handleUnauthorized = function () {
        var _this = this;
        // auto logout if 401 response returned from api
        this.snackBar.open('登入失效，請重新登入。', '', {
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
        this.patSignIn.signOut().then(function () {
            _this.router.navigateByUrl('')
                .then(function () {
                location.reload();
            });
        });
    };
    ErrorInterceptor.prototype.checkIfUnauthorized = function () {
        if (!this.patSignIn.checkSignInStatus()) {
            this.handleUnauthorized();
        }
    };
    ErrorInterceptor.prototype.retryOnInternalServerError = function (error) {
        var isFiltered = error
            && error.url
            && (error.url.includes('/api/tracking/track') || error.url.includes('/api/auth/') || error.url.includes('/api/ugc'));
        var timeout = environment.httpRetryTimeout;
        return error.status >= 500 && !isFiltered ? timer(timeout) : throwError(error);
    };
    ErrorInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorInterceptor_Factory() { return new ErrorInterceptor(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.PatSignInService), i0.ɵɵinject(i3.MatSnackBar)); }, token: ErrorInterceptor, providedIn: "root" });
    return ErrorInterceptor;
}());
export { ErrorInterceptor };
