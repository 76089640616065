import {Component, OnInit} from '@angular/core';
import {LinkService} from './core/service/link.service';
import {environment} from '../environments/environment.prod';
import {PatSignInService} from './core/service/pat-sign-in.service';
import {TrackingApiService} from './core/service/tracking-api.service';
import {NavigationEnd, Router} from '@angular/router';
import {BaseComponent} from './core/component/base.component';
import {filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Utils} from './core/util/Utils';
import {TrackingEvent} from './core/model/tracking/tracking-event.model';
import {PageView} from './core/model/tracking/page-view.model';
import {EventInfo} from './core/model/tracking/event-info.model';
import {SignInDialogComponent} from './core/component/sign-in/sign-in-dialog.component';
import {MatDialog, MatSnackBar} from '@angular/material';
import {AppContextService} from './app.context.service';

// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent extends BaseComponent implements OnInit {
  schema = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: environment.host,
    name: 'PaterMater',
    alternateName: '上有高堂',
    contactPoint: {
      '@type': 'ContactPoint',
      email: 'hello@patermater.org',
      contactType: 'Customer service'
    }
  };

  signInState = 'UNSIGNED_IN';
  isSignedIn = false;

  constructor(private readonly linkService: LinkService,
              private patSignInService: PatSignInService,
              private trackingApiService: TrackingApiService,
              private router: Router,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private appContextService: AppContextService) {
    super();
    linkService.startRouteListener();
  }

  ngOnInit(): void {
    this.patSignInService.user$.pipe(
      takeUntil(this.unsubscribe),
      filter(user => !Utils.isEmpty(user)),
      tap(user => this.userUrn = user.userUrn)
    ).subscribe();

    this.patSignInService.signInState$.pipe(
      takeUntil(this.unsubscribe),
      tap(signInState => {
        this.signInState = signInState;
        this.isSignedIn = 'SIGNED_IN' === this.signInState;
      })
    ).subscribe();

    this.router.events.pipe(
      takeUntil(this.unsubscribe),
      filter(e => e instanceof NavigationEnd),
      switchMap((e: NavigationEnd) => {
        gtag('config', 'G-QKY2LNQW2S', { page_path: e.urlAfterRedirects });
        return this.trackingApiService.createPageViewEvent(this.buildPageViewEvent(e.url));
      })
    ).subscribe();

    this.appContextService.getSchemaUpdate().pipe(
      takeUntil(this.unsubscribe),
      filter(schema => !Utils.isEmpty(schema)),
      tap(schema => this.schema = schema)
    ).subscribe();

    gtag('get', 'G-QKY2LNQW2S', 'client_id', (client_id) => {
      this.gaClientId = client_id;
    });
  }

  login() {
    this.openSignIn();
  }

  logout() {
    this.patSignInService.signOut().then(() => {
      this.router.navigateByUrl('')
        .then(() => {
          location.reload();
        });
    });
  }

  private openSignIn() {
    const dialogRef = this.dialog.open(SignInDialogComponent, {
      width: '600px',
      closeOnNavigation: true,
      hasBackdrop: true,
      autoFocus: false,
      data: {
        redirectUrl: location.pathname
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SIGNED_IN') {
        this.snackBar.open('登入成功！', '', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
      }
    });
  }

  private buildPageViewEvent(pageKey: string): TrackingEvent<PageView> {
    const pageViewEvent = new TrackingEvent<PageView>();
    const eventInfo = new EventInfo();
    const pageView = new PageView();

    eventInfo.application = 'askpm';
    eventInfo.eventName = 'PageViewEvent';
    eventInfo.topic = 'view';

    pageView.gaClientId = this.gaClientId;
    pageView.pageKey = pageKey;
    pageView.userUrn = this.userUrn;
    pageView.clientTimestamp = Date.now();

    pageViewEvent.eventInfo = eventInfo;
    pageViewEvent.eventBody = pageView;

    return pageViewEvent;
  }
}
