import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ViewRef } from '@angular/core';
import { AbstractUserProfileComponent } from './abstract-user-profile.component';
import { UserService } from '../../service/user.service';
import { filter, flatMap, takeUntil, tap } from 'rxjs/operators';
import { Utils } from '../../util/Utils';
var UserProfileSimplifiedComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserProfileSimplifiedComponent, _super);
    function UserProfileSimplifiedComponent(userService, cdRef) {
        var _this = _super.call(this) || this;
        _this.userService = userService;
        _this.cdRef = cdRef;
        _this.DEFAULT_AVATAR_URL = 'https://avatars.dicebear.com/v2/initials/';
        _this.DEFAULT_AVATAR_FORMAT = '.svg';
        return _this;
    }
    UserProfileSimplifiedComponent.prototype._onInit = function () {
        var _this = this;
        this.userUrn$.pipe(takeUntil(this.unsubscribe), filter(function (userUrn) { return !Utils.isEmpty(userUrn); }), flatMap(function (userUrn) { return _this.getUserProfile(userUrn); }), tap(function (user) {
            _this.user = user;
            if (_this.cdRef && !_this.cdRef.destroyed) {
                _this.cdRef.detectChanges();
            }
        })).subscribe();
    };
    UserProfileSimplifiedComponent.prototype.getAvatar = function (user) {
        if (!Utils.isEmpty(user) && !Utils.isEmpty(user.avatarUrl)) {
            return user.avatarUrl;
        }
        else if (!Utils.isEmpty(user) && !Utils.isEmpty(user.username)) {
            return "" + this.DEFAULT_AVATAR_URL + user.username + this.DEFAULT_AVATAR_FORMAT;
        }
        else {
            return this.DEFAULT_AVATAR_URL + "\u4E0A\u6709\u9AD8\u5802" + this.DEFAULT_AVATAR_FORMAT;
        }
    };
    UserProfileSimplifiedComponent.prototype.hashCode = function (s) {
        var h = 0;
        var l = s.length;
        var i = 0;
        if (l > 0) {
            while (i < l) {
                h = (h << 5) - h + s.charCodeAt(i++) | 0;
            }
            return h;
        }
    };
    UserProfileSimplifiedComponent.prototype.getAchievement = function () {
        var mockAchievements = [
            '與爸爸征服癌魔',
            '我係編程這個平台的工程師',
            '我係這個平台的設計師',
            '回答了746條問題',
            '每個禮拜都陪嫲嫲飲茶',
            '安裝了醫院床',
            '請爸媽去杜拜旅行',
            '每天都和媽媽傾訴15分鐘',
            '幫媽媽安排了復古的生日party',
            '上手做營養餐',
            '煎牛排俾爸爸食點知燒燶咗',
            '寫了一篇出院攻略的文章',
            '舉辦了4場自親活動'
        ];
        return mockAchievements[Math.floor(Math.random() * mockAchievements.length)];
    };
    UserProfileSimplifiedComponent.prototype.getUserProfile = function (userUrn) {
        return this.userService.getPublicUserProfile(userUrn);
    };
    return UserProfileSimplifiedComponent;
}(AbstractUserProfileComponent));
export { UserProfileSimplifiedComponent };
