import * as tslib_1 from "tslib";
import { PatSignInService } from '../../service/pat-sign-in.service';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../base.component';
import { SignInStatementTermsComponent } from './statement-terms.component';
import { SignInStatementPrivacyComponent } from './statement-privacy.component';
var SignInDialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SignInDialogComponent, _super);
    function SignInDialogComponent(data, patSignIn, dialogRef, snackBar, spinner, dialog) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.patSignIn = patSignIn;
        _this.dialogRef = dialogRef;
        _this.snackBar = snackBar;
        _this.spinner = spinner;
        _this.dialog = dialog;
        _this.email = new FormControl('', [Validators.required, Validators.email]);
        _this.phoneNumber = new FormControl('', Validators.required);
        _this.signInBtnClicked = false;
        _this.emailSent = false;
        _this.smsSent = false;
        _this.redirectUrl = _this.data.redirectUrl;
        return _this;
    }
    SignInDialogComponent.prototype.signInWithEmail = function () {
        var _this = this;
        if (this.email.hasError('required') || this.email.hasError('email')) {
            return;
        }
        this.signInBtnClicked = true;
        var req = {
            email: this.email.value,
            redirectUrl: this.redirectUrl
        };
        this.spinner.show('emailSpinner');
        this.patSignIn.signInWithMagicLink(req)
            .subscribe(function () {
            _this.signInBtnClicked = false;
            _this.spinner.hide('emailSpinner')
                .then(function () {
                _this.emailSent = true;
            });
        });
    };
    SignInDialogComponent.prototype.signInWithMobile = function () {
        var _this = this;
        if (this.phoneNumber.hasError('required')) {
            return;
        }
        this.signInBtnClicked = true;
        var req = {
            mobile: {
                areaCode: this.areaCode,
                phoneNumber: this.phoneNumber.value
            },
            redirectUrl: this.redirectUrl
        };
        this.spinner.show('smsSpinner');
        this.patSignIn.signInWithMagicLink(req)
            .subscribe(function () {
            _this.signInBtnClicked = false;
            _this.spinner.hide('smsSpinner')
                .then(function () {
                _this.smsSent = true;
            });
        });
    };
    SignInDialogComponent.prototype.signInWithGoogle = function () {
        var _this = this;
        this.spinner.show('socialPluginSpinner');
        this.patSignIn.signInWithGoogle()
            .then(function () {
            _this.spinner.hide('socialPluginSpinner')
                .then(function () {
                _this.dialogRef.close('SIGNED_IN');
                location.reload();
            });
        }, function (err) {
            _this.snackBar.open('登入失敗，請稍後再試。', '', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'right'
            });
        });
    };
    SignInDialogComponent.prototype.getEmailErrorMessage = function () {
        return this.email.hasError('required') ? 'You must enter a value' :
            this.email.hasError('email') ? 'Not a valid email' :
                '';
    };
    SignInDialogComponent.prototype.getSmsErrorMessage = function () {
        return this.email.hasError('required') ? 'You must enter a value' : '';
    };
    SignInDialogComponent.prototype.openTerms = function () {
        this.dialog.open(SignInStatementTermsComponent);
    };
    SignInDialogComponent.prototype.openPrivacy = function () {
        this.dialog.open(SignInStatementPrivacyComponent);
    };
    return SignInDialogComponent;
}(BaseComponent));
export { SignInDialogComponent };
