import {Component, Inject} from '@angular/core';
import {PatSignInService} from '../../service/pat-sign-in.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormControl, Validators} from '@angular/forms';
import {BaseComponent} from '../base.component';
import {SignInStatementTermsComponent} from './statement-terms.component';
import {SignInStatementPrivacyComponent} from './statement-privacy.component';

@Component({
  selector: 'pat-sign-in-dialog',
  templateUrl: './sign-in-dialog.component.html',
  styleUrls: [],
  providers: [
    PatSignInService
  ]
})
export class SignInDialogComponent extends BaseComponent {
  private readonly redirectUrl;

  email = new FormControl('', [Validators.required, Validators.email]);
  areaCode: string;
  phoneNumber = new FormControl('', Validators.required);

  signInBtnClicked = false;
  emailSent = false;
  smsSent = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
              private patSignIn: PatSignInService,
              private dialogRef: MatDialogRef<SignInDialogComponent>,
              private snackBar: MatSnackBar,
              private spinner: NgxSpinnerService,
              private dialog: MatDialog) {
    super();
    this.redirectUrl = this.data.redirectUrl;
  }

  signInWithEmail() {
    if (this.email.hasError('required') || this.email.hasError('email')) {
      return;
    }
    this.signInBtnClicked = true;
    const req = {
      email: this.email.value,
      redirectUrl: this.redirectUrl
    };
    this.spinner.show('emailSpinner');
    this.patSignIn.signInWithMagicLink(req)
      .subscribe(() => {
        this.signInBtnClicked = false;
        this.spinner.hide('emailSpinner')
          .then(() => {
            this.emailSent = true;
          });
      });
  }

  signInWithMobile() {
    if (this.phoneNumber.hasError('required')) {
      return;
    }
    this.signInBtnClicked = true;
    const req = {
      mobile: {
        areaCode: this.areaCode,
        phoneNumber: this.phoneNumber.value
      },
      redirectUrl: this.redirectUrl
    };
    this.spinner.show('smsSpinner');
    this.patSignIn.signInWithMagicLink(req)
      .subscribe(() => {
        this.signInBtnClicked = false;
        this.spinner.hide('smsSpinner')
          .then(() => {
            this.smsSent = true;
          });
      });
  }

  signInWithGoogle() {
    this.spinner.show('socialPluginSpinner');
    this.patSignIn.signInWithGoogle()
      .then(() => {
        this.spinner.hide('socialPluginSpinner')
          .then(() => {
            this.dialogRef.close('SIGNED_IN');
            location.reload();
          });
        }, err => {
        this.snackBar.open('登入失敗，請稍後再試。', '', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
      });
  }

  getEmailErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
      this.email.hasError('email') ? 'Not a valid email' :
        '';
  }

  getSmsErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' : '';
  }

  openTerms() {
    this.dialog.open(SignInStatementTermsComponent);
  }

  openPrivacy() {
    this.dialog.open(SignInStatementPrivacyComponent);
  }
}
