import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewRef} from '@angular/core';
import {AbstractUserProfileComponent} from './abstract-user-profile.component';
import {UserService} from '../../service/user.service';
import {filter, flatMap, takeUntil, tap} from 'rxjs/operators';
import {Utils} from '../../util/Utils';
import {PublicUserProfile} from './public-user-profile.model';

@Component({
  selector: 'user-profile-simplified',
  templateUrl: './user-profile-simplified.component.html',
  styleUrls: ['./user-profile-simplified.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileSimplifiedComponent extends AbstractUserProfileComponent {
  private readonly DEFAULT_AVATAR_URL = 'https://avatars.dicebear.com/v2/initials/';
  private readonly DEFAULT_AVATAR_FORMAT = '.svg';

  constructor(private userService: UserService,
              private cdRef: ChangeDetectorRef) {
    super();
  }

  _onInit() {
    this.userUrn$.pipe(
      takeUntil(this.unsubscribe),
      filter(userUrn => !Utils.isEmpty(userUrn)),
      flatMap(userUrn => this.getUserProfile(userUrn)),
      tap(user => {
        this.user = user;
        if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
          this.cdRef.detectChanges();
        }
      })
    ).subscribe();
  }

  getAvatar(user: PublicUserProfile) {
    if (!Utils.isEmpty(user) && !Utils.isEmpty(user.avatarUrl)) {
      return user.avatarUrl;
    } else if (!Utils.isEmpty(user) && !Utils.isEmpty(user.username)) {
      return `${this.DEFAULT_AVATAR_URL}${user.username}${this.DEFAULT_AVATAR_FORMAT}`;
    } else {
      return `${this.DEFAULT_AVATAR_URL}上有高堂${this.DEFAULT_AVATAR_FORMAT}`;
    }
  }

  hashCode(s: string) {
    let h = 0;
    const l = s.length;
    let i = 0;
    if ( l > 0 ) {
      while (i < l) {
        h = (h << 5) - h + s.charCodeAt(i++) | 0;
      }
      return h;
    }
  }

  getAchievement() {
    const mockAchievements = [
      '與爸爸征服癌魔',
      '我係編程這個平台的工程師',
      '我係這個平台的設計師',
      '回答了746條問題',
      '每個禮拜都陪嫲嫲飲茶',
      '安裝了醫院床',
      '請爸媽去杜拜旅行',
      '每天都和媽媽傾訴15分鐘',
      '幫媽媽安排了復古的生日party',
      '上手做營養餐',
      '煎牛排俾爸爸食點知燒燶咗',
      '寫了一篇出院攻略的文章',
      '舉辦了4場自親活動'];
    return mockAchievements[Math.floor(Math.random() * mockAchievements.length)];
  }

  private getUserProfile(userUrn: string) {
    return this.userService.getPublicUserProfile(userUrn);
  }
}
