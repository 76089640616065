import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {PatSignInService} from './pat-sign-in.service';
import {Injectable} from '@angular/core';
import {SignInDialogComponent} from '../component/sign-in/sign-in-dialog.component';
import {MatDialog, MatSnackBar} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private currentUser;

  constructor(private router: Router,
              private patSignIn: PatSignInService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) {
    this.patSignIn.user$.subscribe(value => {
      this.currentUser = value;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.currentUser) {
      return true;
    }

    this.openSignIn();
    return false;
  }

  private openSignIn() {
    const dialogRef = this.dialog.open(SignInDialogComponent, {
      width: '600px',
      closeOnNavigation: true,
      hasBackdrop: true,
      autoFocus: false,
      data: {
        redirectUrl: location.pathname
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SIGNED_IN') {
        this.snackBar.open('登入成功！', '', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
      }
    });
  }
}
