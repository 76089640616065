import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {tap} from 'rxjs/internal/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginUrl = 'api/auth/login';
  private magicLinkUrl = 'api/auth/magicAuth';

  constructor(
    private http: HttpClient) {}

  login(credential: any): Observable<string> {
    const url = this.loginUrl;
    const options: object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text'
    };
    return this.http.post<string>(url, credential, options)
      .pipe(
        tap(response => {
          localStorage.setItem('authToken', response);
        })
      );
  }

  magicLinkSignIn(data: any) {
    const url = this.magicLinkUrl;
    const options: object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, data, options);
  }

  authenticateMagicToken(token: string): Observable<string> {
    const url = this.magicLinkUrl + '?token=' + token;
    const options: object = {
      responseType: 'text'
    };
    return this.http.get<string>(url, options)
      .pipe(
        tap(response => {
          console.log(response);
          localStorage.setItem('authToken', response);
        }));
  }
}
