<div class="media">
  <a class="d-flex align-self-center mr-2">
    <img class="avatar rounded-circle"
         data-v-5c6caf12=""
         [src]="getAvatar(user)" alt="" />
  </a>
  <div class="media-body align-self-center">
    <p class="username">
      <span class="font-pixel">{{ user?.username }}</span>
<!--      <br><span style="color: #FF5733;font-size: 14px;font-style: italic;">-->
<!--      <i class="fas fa-medal"></i>{{getAchievement()}}</span>-->
    </p>
<!--    <em class="d-block description font-size-12">{{ user?.description }}</em>-->
  </div>
</div>
