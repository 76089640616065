<div mat-dialog-content>
  <button type="button" class="close" style="float: unset" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-body">
    <h3 class="text-center" style="font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';"><strong>網頁使用條款</strong></h3>
    <p>請在使用網站前仔細閱讀本條款。閣下使用本網站，即表示閣下同意受本條款約束。上有高堂可能不時修訂本條款，並在發佈於其網站或應用程式時生效。閣下在發佈經修訂的本條款後繼續使用本網站，即表示閣下同意接受經修訂的該等條款約束。</p>
    <ol>
      <strong><p>帳戶資料、密碼及保安</p></strong>

      <li><p>閣下確認︰</p></li>

      <ol><li><p>在遞交予上有高堂的所有在線及離線登記表上及其他文件中，提供的資料為準確及完整；</p></li>
        <li><p>上有高堂擁有絕對酌情權可於不一定向閣下發出通知的情況下，因違反任何條款或其他原因而終止閣下進入、瀏覽，或終止閣下上有高堂的賬戶及其他相關優惠，且毋須就此向閣下承擔法律責任或追索權；及</p></li>
        <li><p>閣下須將閣下的上有高堂賬戶登入密碼及資料或其他登記資料保密，並負責其安全。 閣下同意如閣下的密碼或資料在未獲閣下授權的情況下遭他人使用或存取，閣下會立刻通知上有高堂。上有高堂將採取所有其認為適當的行動，以確保上有高堂的安全及完整，該等行動包括但不限於監察及記錄互聯網活動及網絡位址、核實用戶資料、追查及追蹤資料流向。</p></li></ol>

      <strong>知識產權</strong>

      <li><p>閣下同意瀏覽本網站時︰</p></li>

      <ol><li><p>不會進行任何違反知識產權法或侵害本網站內任何資料的知識產權及／或其他專有權利或導致其受侵害的活動，閣下亦同意不會將上有高堂的任何部分連接至任何可能會進行上述侵權活動的網站；</p></li>
        <li><p>不會違反任何保護知識產權的措施，包括揑改序號或登記號碼，或使用駭客工具；及</p></li>
        <li><p>不會在未經他人授權或同意下，以任何方式抄錄、套取或利用他人網頁的內容。</p></li></ol>

      <li><p>倘發現用戶進行侵犯知識產權的活動，本網站有權在不發出通告，且毋須向閣下承擔法律責任及追索權的情況下︰ 移除任何其認為就知識產權法、知識產權而言屬侵權或在其他方面違反條款的帖子、內容或資料，及終止用戶的相關登記或戶口，並考慮採取適當的法律行動。</p></li>


      <strong>資訊及內容</strong>

      <li><p>上有高堂或透過電郵提供產品及╱或服務的資訊。網頁及電郵上發佈的資訊內容、資料、產品及╱或服務均基於「現況」及「現有」的基礎提供。</p></li>

      <li><p>在現行法律最大容許的情況下，上有高堂不就網頁及電郵上的資訊提供任何明示或默示的擔保，包含但不限於任何第三方內容、資料、產品及╱或服務的擁有權、商業適售性、質量保證、特定目的之適用性及未侵害第三方的權利。</p></li>

      <li><p>上有高堂不對閣下經由或透過本網頁或電郵購買或取得之任何第三方產品或服務或交易作出任何保證。上有高堂僅為第三方產品或服務供應商提供產品或服務推廣及宣傳渠道，並不控制任何第三方就產品或服務提供的實際操作。閣下確認及知悉如對第三方產品或服務供應商的產品或服務有任何疑問或投訴，閣下需自行向該等第三方產品或服務供應商查詢。上有高堂不承擔閣下因與第三方產品或服務供應商交易所造成的任何損失。</p></li>

      <li><p>因透過瀏覽本網頁及上有高堂發送的電郵開啟及下載該等資訊而導致閣下電腦系統的任何損壞或資料流失，閣下需自行承擔風險。上有高堂不承擔閣下因此而所造成的任何損失。</p></li>

      <li><p>上有高堂不負責亦不承擔任何一切因使用本網頁或本服務而引致之任何意外、錯誤、遺漏、疏忽、干擾、經傳送或接收的錯誤、通信線路失靈、線上通信的攔截、因下載而感染電腦病毒、誹謗、合約毀壞、版權或知識產權侵犯、疏忽或任何其他因素所引起的任何損失。</p></li>

      <strong>連結網頁</strong>

      <li><p>上有高堂不控制任何第三方連結網頁，亦不對任何第三方連結網頁所附載的內容，或該等網頁內的任何更改或更新負責。上有高堂提供此等連結僅為給予閣下個人的便利，任何第三方連結上的裝設/附載並不代表已獲取上有高堂的認可，或確認與其營運者的聯繫。閣下同意上有高堂不會就使用任何連結網頁的服務或內容所做成之任何損失或損害負上法律責任。</p></li>

      <strong>網頁服務的提供</strong>

      <li><p>上有高堂不保證閣下能可持續地接達本網頁。上有高堂保留將本網站的任何部分或全部離線的權利，包括緊急系統維修或內容提升。</p></li>

      <strong>賠償</strong>

      <li><p>上有高堂不承擔任何因本網頁資訊內容、資料、產品及╱或服務所產生的直接、間接、 附帶的、特別的、衍生性或懲罰性賠償。如有關索償是因上有高堂的疏忽或有意行為造成的，賠償額僅限於上有高堂就該項服務從閣下所收取的費用（如有），唯賠償額無論如何不超過港幣一千元。</p></li></ol>
    <p>最後更新日期: 2020年8月1日</p>
  </div>
</div>
