<header class="d-flex secondary top-nav-bar sticky-top">
  <button class="d-flex align-items-center btn-header hamburger" (click)="burgerClicked.emit()">
    <i class="fas fa-bars font-size-18"></i>
  </button>
  <button class="d-flex align-items-center btn-header back-link" *ngIf="isBackEnabled()" (click)="goBack()">
    <i class="fas fa-arrow-left"></i>
  </button>
  <div class="media" [formGroup]="form">
    <div class="media-body d-flex align-self-center justify-content-center">
      <div class="input-wrapper">
        <input type="text"
               placeholder="問問上有高堂"
               formControlName="title" />
        <i class="fas fa-search clickable" (click)="search()"></i>
      </div>
    </div>
  </div>
</header>
