import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FilterModel} from '../model/filter.model';

@Injectable({
  providedIn: 'root'
})
export class NavSearchService {

  private filterModel$: BehaviorSubject<FilterModel> = new BehaviorSubject<any>(null);
  private filterTag$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  getFilterModel(): Observable<FilterModel> {
    return this.filterModel$.asObservable();
  }

  updateFilterModel(filterModel: FilterModel) {
    this.filterModel$.next(filterModel);
  }

  getFilterTag(): Observable<string> {
    return this.filterTag$.asObservable();
  }

  updateFilterTag(tagName: string) {
    this.filterTag$.next(tagName);
  }
}
