import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../base.component';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Utils} from '../../util/Utils';
import {NavSearchService} from '../../service/nav-search.service';
import {FilterModel} from '../../model/filter.model';

@Component({
  selector: 'askpm-forum-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less']
})
export class ForumNavComponent extends BaseComponent implements OnInit {
  @Output() burgerClicked: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  private currentSearchKeyword = '';

  constructor(private fb: FormBuilder,
              private router: Router,
              private location: Location,
              private navSearchService: NavSearchService) {
    super();
    this.initForm();
  }

  private initForm() {
    this.form = this.fb.group({
      title: new FormControl('', [])
    });
  }

  ngOnInit() {

  }

  search() {
    const value = this.form.get('title').value;
    if (!Utils.isEmpty(value) && this.currentSearchKeyword !== value) {
      this.currentSearchKeyword = value;
      const filterModel = new FilterModel();
      filterModel.title = value;
      this.navSearchService.updateFilterModel(filterModel);
      this.router.navigate(['searchResult']).then(() => {
        this.form.reset();
        this.currentSearchKeyword = '';
      });
    }
  }

  isBackEnabled(): boolean {
    return this.router.url.includes('/q/') || this.router.url.includes('/searchResult');
  }

  goBack() {
    this.router.navigate(['']);
  }
}
