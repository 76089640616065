<h2 class="text-center pt-1 pb-2" mat-dialog-title>
  歡迎回來！
</h2>
<div class="text-center" mat-dialog-content>
  <p style="line-height: 1.8rem;">
    登入以取得個人化的資訊、
    <br>
    追蹤上有高堂最新最全面的內容、
    <br>
    以及與我們的社群交流互動！
  </p>
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="以電郵登入" *ngIf="false">
      <div *ngIf="!emailSent">
        <mat-form-field class="pt-2" [floatLabel]="true" [hideRequiredMarker]="true">
          <input matInput type="email" placeholder="電郵地址" [formControl]="email" required>
          <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
        </mat-form-field>
        <p>
          <button type="submit" class="btn btn-primary" (click)="signInWithEmail()" [disabled]="signInBtnClicked">繼續</button>
        </p>
        <p class="text-muted font-size-12">
          按下繼續後，一封帶有登入網址的電郵將會傳送到你輸入的郵箱。
        </p>
      </div>
      <div class="pt-2">
        <ngx-spinner name="emailSpinner" [fullScreen]="false">
          <p class="white">處理中⋯⋯</p>
        </ngx-spinner>
        <p *ngIf="emailSent">
          電子郵件已發出，請查閱電子郵箱。
        </p>
      </div>
    </mat-tab>
    <mat-tab label="以電話號碼登入" *ngIf="false">
      <div *ngIf="!smsSent">
        <mat-form-field class="pt-2" [floatLabel]="true" [hideRequiredMarker]="true" style="width: 70px;">
          <mat-label>地區</mat-label>
          <mat-select [(ngModel)]="areaCode" required>
            <mat-option value="+852">香港</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="pt-2" [floatLabel]="true" [hideRequiredMarker]="true" style="width: 150px;">
          <input matInput type="tel" placeholder="電話號碼" [formControl]="phoneNumber" required>
          <mat-error *ngIf="phoneNumber.invalid">{{getSmsErrorMessage()}}</mat-error>
        </mat-form-field>
        <p>
          <button type="submit" class="btn btn-primary" (click)="signInWithMobile()" [disabled]="signInBtnClicked">繼續</button>
        </p>
        <p class="text-muted font-size-12">
          按下繼續後，一個帶有登入網址的短訊將會傳送到你輸入的電話號碼。
        </p>
      </div>
      <div class="pt-2">
        <ngx-spinner name="smsSpinner" [fullScreen]="false">
          <p class="white">處理中⋯⋯</p>
        </ngx-spinner>
        <p *ngIf="smsSent">
          短訊已發出，請查閱信箱。
        </p>
      </div>
    </mat-tab>
    <mat-tab label="以社交媒體帳戶登入">
      <button type="button" class="btn btn-google-auth my-3" (click)="signInWithGoogle()">
        <span class="google-auth-icon"></span>
        <span class="google-auth-text">以 Google 登入</span>
      </button>
      <ngx-spinner name="socialPluginSpinner" type="ball-8bits" [fullScreen]="false">
        <p class="white">登入中⋯⋯</p>
      </ngx-spinner>
    </mat-tab>
  </mat-tab-group>
  <p class="font-size-12 pt-3">
    上有高堂儲存用戶資料以改善服務，<br>
    按下登入即代表你接受我們的<br>
    <span class="clickable" (click)="openPrivacy()">私隱政策</span> 及 <span class="clickable" (click)="openTerms()">網頁使用條款</span>。
  </p>
</div>
