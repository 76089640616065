<section class="container-fluid">
  <div class="text-center mt-5">
    <h3>找不到網頁</h3>
    <p class="py-5">
      <img src="../../../../assets/img/404.png" alt="" height="200">
    </p>
    <p>
      <a routerLink="">返回主頁</a>
    </p>
    <a href="mailto:hello@patermater.org">聯絡我們</a>
    或到
    <a target="_blank" title="find us on Facebook" href="https://www.facebook.com/PaterMaterHK">
      <img alt="follow me on facebook" src="//login.create.net/images/icons/user/facebook_40x40.png" border="0">
    </a>
    查詢
  </div>
</section>
