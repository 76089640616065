import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var NavSearchService = /** @class */ (function () {
    function NavSearchService() {
        this.filterModel$ = new BehaviorSubject(null);
        this.filterTag$ = new BehaviorSubject(null);
    }
    NavSearchService.prototype.getFilterModel = function () {
        return this.filterModel$.asObservable();
    };
    NavSearchService.prototype.updateFilterModel = function (filterModel) {
        this.filterModel$.next(filterModel);
    };
    NavSearchService.prototype.getFilterTag = function () {
        return this.filterTag$.asObservable();
    };
    NavSearchService.prototype.updateFilterTag = function (tagName) {
        this.filterTag$.next(tagName);
    };
    NavSearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavSearchService_Factory() { return new NavSearchService(); }, token: NavSearchService, providedIn: "root" });
    return NavSearchService;
}());
export { NavSearchService };
