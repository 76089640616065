import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {RouteReuseStrategy} from '@angular/router';
import {AppRoutingStrategy} from './app-routing.strategy';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppMaterialModule} from './app.material.module';
import {MarkdownModule} from 'ngx-markdown';
import {NgxJsonLdModule} from '@ngx-lite/json-ld';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ErrorInterceptor} from './core/service/error-interceptor.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    AppMaterialModule,
    MarkdownModule.forRoot(),
    NgxJsonLdModule,
    NgxSpinnerModule,
    CoreModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppRoutingStrategy
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
