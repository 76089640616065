import { Router } from '@angular/router';
import { PatSignInService } from './pat-sign-in.service';
import { SignInDialogComponent } from '../component/sign-in/sign-in-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./pat-sign-in.service";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/material/snack-bar";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, patSignIn, dialog, snackBar) {
        var _this = this;
        this.router = router;
        this.patSignIn = patSignIn;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.patSignIn.user$.subscribe(function (value) {
            _this.currentUser = value;
        });
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        if (this.currentUser) {
            return true;
        }
        this.openSignIn();
        return false;
    };
    AuthGuard.prototype.openSignIn = function () {
        var _this = this;
        var dialogRef = this.dialog.open(SignInDialogComponent, {
            width: '600px',
            closeOnNavigation: true,
            hasBackdrop: true,
            autoFocus: false,
            data: {
                redirectUrl: location.pathname
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === 'SIGNED_IN') {
                _this.snackBar.open('登入成功！', '', {
                    duration: 3000,
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                });
            }
        });
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.PatSignInService), i0.ɵɵinject(i3.MatDialog), i0.ɵɵinject(i4.MatSnackBar)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
