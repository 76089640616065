import {Component} from '@angular/core';

@Component({
  selector: 'askpm-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: []
})
export class HelpCenterComponent {
  constructor() { }
}
