import {Input, OnInit} from '@angular/core';
import {UserService} from '../../service/user.service';
import {BaseComponent} from '../base.component';
import {Utils} from '../../util/Utils';
import {BehaviorSubject} from 'rxjs';
import {PublicUserProfile} from './public-user-profile.model';


export abstract class AbstractUserProfileComponent extends BaseComponent implements OnInit {
  user: PublicUserProfile;

  protected userUrn$ = new BehaviorSubject<string>('');

  @Input('user-urn') set userUrn(userUrn: string) {
    if (!Utils.isEmpty(userUrn)) {
      this.userUrn$.next(userUrn);
    }
  }

  @Input() set userProfile(userProfile: PublicUserProfile) {
    if (!Utils.isEmpty(userProfile)) {
      this.user = userProfile;
    }
  }

  protected constructor() {
    super();
  }

  ngOnInit(): void {
    this._onInit();
  }

  abstract _onInit();
}
