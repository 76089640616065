<div mat-dialog-content>
  <button type="button" class="close" style="float: unset" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-body">
    <h3 class="text-center" style="font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';"><strong>私隱政策</strong></h3>
    <p>上有高堂致力保障及維護閣下個人資料的安全，並承諾根據香港之個人資料 (私隱) 條例 (第486章) 保障閣下的私隱權利。</p>
    <p>當網站接收到閣下的個人資料時，網站系統會即時保存妥當並予以保密，只有得到授權及相關人員才可獲准接觸有關資料，閣下的個人資料亦可能被傳送到以下公司或人士以便提供相關服務或遵守相關法例或命令： </p>
    <ol type="I">
      <li>相關第三方產品或服務供應商；</li>
      <li>外判服務商，如網絡系統承辦商或其他服務提供者；</li>
      <li>其他執行資料保安工作的人士及機構；及</li>
      <li>根據有關法律或法庭頒令而指示要求披露有關資料的政府部門或法定機構。</li>
    </ol>
    <p>上有高堂將透過閣下提供的聯繫方式發送推廣訊息予閣下。一般推廣訊息包括最新優惠、推廣、講座、活動、產品及服務，以及我們合作夥伴的最新情報和訊息，並或會邀請閣下參加意見調查。我們將主要使用閣下的電郵地址及Whatsapp通知閣下上述資訊。若閣下希望停止接收上有高堂發出的推廣訊息，請以書面或電郵至 hello@patermater.org 通知，上有高堂將於30天內辦理閣下的指示，不會收取任何費用。</p>
    <p>當閣下瀏覽網站時，上有高堂或會與第三方以第一方 Cookies 及第三方識別碼方式不記名搜集閣下使用網站的瀏覽活動資訊。為進一步了解訪客使用體驗，本網站已安裝Google Analytics 等功能。若閣下希望停止使用分析廣告功能，可以到訪Google等相關服務網頁查詢。</p>
    <p>閣下有權向上有高堂查閱、更正及更新閣下的個人資料。查閱資料可電郵至hello@patermater.org。倘閣下對上有高堂的私隱政策聲明有任何疑問或意見，可電郵至hello@patermater.org與我們聯絡。</p>
    <p>最後更新日期: 2020年8月1日</p>
  </div>
</div>
