import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PublicUserProfile} from '../component/user-profile/public-user-profile.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private profileUrl = 'api/auth/userProfile';
  private publicUserProfileUrl = 'api/auth/publicUserProfiles';

  constructor(private http: HttpClient) {}

  getAllUserProfile(): Observable<PublicUserProfile[]> {
    const url = this.publicUserProfileUrl;
    const options: object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-AUTH-PATERMATER-TOKEN': localStorage.getItem('authToken')
      })
    };
    return this.http.get<PublicUserProfile[]>(url, options);
  }

  getPublicUserProfile(userUrn: string): Observable<PublicUserProfile> {
    const url = this.publicUserProfileUrl + '/' + userUrn;
    return this.http.get<PublicUserProfile>(url);
  }

  getUserProfile(userId: string) {
    const url = this.profileUrl + '/' + userId;
    const options: object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-AUTH-PATERMATER-TOKEN': localStorage.getItem('authToken')
      })
    };
    return this.http.get(url, options);
  }

  updateUserProfile(userId: string, data: any) {
    const url = this.profileUrl + '/' + userId;
    const options: object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-AUTH-PATERMATER-TOKEN': localStorage.getItem('authToken')
      })
    };
    return this.http.put(url, data, options);
  }

  uploadAvatar(data: File) {
    const url = `${this.profileUrl}/uploadProfilePicture`;
    const options: object = {
      headers: new HttpHeaders({
        'X-AUTH-PATERMATER-TOKEN': localStorage.getItem('authToken')
      })
    };
    const formData: FormData = new FormData();
    formData.append('avatarImage', data, data.name);
    return this.http.post(url, formData, options);
  }
}
