import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TrackingApiService = /** @class */ (function () {
    function TrackingApiService(http) {
        this.http = http;
        this.TRACKING_ENDPOINT = 'api/tracking/track';
    }
    TrackingApiService.prototype.createPageViewEvent = function (data) {
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'text'
        };
        return this.http.post(this.TRACKING_ENDPOINT + "/pageViewEvent", data, options);
    };
    TrackingApiService.prototype.createProblemPostedEvent = function (data) {
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'text'
        };
        return this.http.post(this.TRACKING_ENDPOINT + "/problemPostedEvent", data, options);
    };
    TrackingApiService.prototype.createSolutionPostedEvent = function (data) {
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'text'
        };
        return this.http.post(this.TRACKING_ENDPOINT + "/solutionPostedEvent", data, options);
    };
    TrackingApiService.prototype.createSolutionVotedEvent = function (data) {
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'text'
        };
        return this.http.post(this.TRACKING_ENDPOINT + "/solutionVotedEvent", data, options);
    };
    TrackingApiService.prototype.createInteractionEvent = function (data) {
        var options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'text'
        };
        return this.http.post(this.TRACKING_ENDPOINT + "/interactionEvent", data, options);
    };
    TrackingApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrackingApiService_Factory() { return new TrackingApiService(i0.ɵɵinject(i1.HttpClient)); }, token: TrackingApiService, providedIn: "root" });
    return TrackingApiService;
}());
export { TrackingApiService };
