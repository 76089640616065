import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NavNavigationBarComponent} from './component/nav/nav.navigation-bar.component';
import {RouterModule} from '@angular/router';
import {SignInDialogComponent} from './component/sign-in/sign-in-dialog.component';
import {AppMaterialModule} from '../app.material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MagicAuthRedirectComponent} from './component/sign-in/magic-auth-redirect.component';
import {NotFoundComponent} from './component/not-found/not-found.component';
import {AdminUserSelectorComponent} from './component/admin/admin.user-selector.component';
import {UserProfileSimplifiedComponent} from './component/user-profile/user-profile-simplified.component';
import {UserProfileNameOnlyComponent} from './component/user-profile/user-profile-name-only.component';
import {MetadataImage} from './directive/metadata-image.directive';
import {DateRenderer} from './directive/date-renderer.directive';
import {UserProfileRenderer} from './directive/user-profile-renderer.directive';
import {EmbedlyContentComponent} from './component/embedly/embedly-content.component';
import {ForumNavComponent} from './component/nav/nav.component';
import {SignInStatementTermsComponent} from './component/sign-in/statement-terms.component';
import {SignInStatementPrivacyComponent} from './component/sign-in/statement-privacy.component';
import {HelpCenterComponent} from './component/help-center/help-center.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    NgxSpinnerModule,
    RouterModule
  ],
  declarations: [
    AdminUserSelectorComponent,
    SignInDialogComponent,
    NavNavigationBarComponent,
    MagicAuthRedirectComponent,
    NotFoundComponent,
    UserProfileSimplifiedComponent,
    UserProfileNameOnlyComponent,
    MetadataImage,
    UserProfileRenderer,
    DateRenderer,
    EmbedlyContentComponent,
    ForumNavComponent,
    SignInStatementTermsComponent,
    SignInStatementPrivacyComponent,
    HelpCenterComponent
  ],
  exports: [
    AdminUserSelectorComponent,
    SignInDialogComponent,
    NavNavigationBarComponent,
    UserProfileSimplifiedComponent,
    UserProfileNameOnlyComponent,
    UserProfileRenderer,
    DateRenderer,
    EmbedlyContentComponent,
    ForumNavComponent
  ],
  entryComponents: [
    SignInDialogComponent,
    UserProfileSimplifiedComponent,
    SignInStatementTermsComponent,
    SignInStatementPrivacyComponent
  ]
})
export class CoreModule { }
