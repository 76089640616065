import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var AppContextService = /** @class */ (function () {
    function AppContextService() {
        this.schema$ = new BehaviorSubject(null);
    }
    AppContextService.prototype.getSchemaUpdate = function () {
        return this.schema$.asObservable();
    };
    AppContextService.prototype.updateSchema = function (schema) {
        this.schema$.next(schema);
    };
    AppContextService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppContextService_Factory() { return new AppContextService(); }, token: AppContextService, providedIn: "root" });
    return AppContextService;
}());
export { AppContextService };
