import { OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/internal/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
var LinkService = /** @class */ (function () {
    function LinkService(document, router) {
        this.document = document;
        this.router = router;
    }
    /**
     * Start listening on NavigationEnd router events
     */
    LinkService.prototype.startRouteListener = function () {
        var _this = this;
        this.routeListener = this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
            var url = '';
            var urlTree = _this.router.parseUrl(_this.router.url);
            if (urlTree.root.hasChildren()) {
                var segments = urlTree.root.children['primary'].segments;
                if (segments && segments.length > 0) {
                    url = segments.map(function (segment) { return segment.path; }).join('/');
                }
            }
            _this.updateTag({
                rel: 'canonical',
                href: "/" + url
            });
        });
    };
    /**
     * Create or update a link tag
     * @param  {LinkDefinition} tag
     */
    LinkService.prototype.updateTag = function (tag) {
        var selector = this._parseSelector(tag);
        var linkElement = this.document.head.querySelector(selector)
            || this.document.head.appendChild(this.document.createElement('link'));
        if (linkElement) {
            Object.keys(tag).forEach(function (prop) {
                linkElement[prop] = tag[prop];
            });
        }
    };
    /**
     * Remove a link tag from DOM
     * @param  tag
     */
    LinkService.prototype.removeTag = function (tag) {
        var selector = this._parseSelector(tag);
        var linkElement = this.document.head.querySelector(selector);
        if (linkElement) {
            this.document.head.removeChild(linkElement);
        }
    };
    /**
     * Get link tag
     * @param  tag
     * @return {HTMLLinkElement}
     */
    LinkService.prototype.getTag = function (tag) {
        var selector = this._parseSelector(tag);
        return this.document.head.querySelector(selector);
    };
    /**
     * Get all link tags
     * @return {NodeListOf<HTMLLinkElement>}
     */
    LinkService.prototype.getTags = function () {
        return this.document.head.querySelectorAll('link');
    };
    /**
     * Parse tag to create a selector
     * @param  tag
     * @return {string} selector to use in querySelector
     */
    LinkService.prototype._parseSelector = function (tag) {
        var attr = tag.rel ? 'rel' : 'hreflang';
        return "link[" + attr + "=\"" + tag[attr] + "\"]";
    };
    /**
     * Destroy route listener when service is destroyed
     */
    LinkService.prototype.ngOnDestroy = function () {
        this.routeListener.unsubscribe();
    };
    LinkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LinkService_Factory() { return new LinkService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i2.Router)); }, token: LinkService, providedIn: "root" });
    return LinkService;
}());
export { LinkService };
