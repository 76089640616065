import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {NEVER, Observable, throwError, timer} from 'rxjs';
import {catchError, mergeMap, retryWhen, take} from 'rxjs/internal/operators';
import {PatSignInService} from './pat-sign-in.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  private _signInState;

  constructor(private router: Router,
              private patSignIn: PatSignInService,
              private snackBar: MatSnackBar) {
    this.patSignIn.signInState$.subscribe(state => this._signInState = state);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        // retryWhen(errors => {
        //   return errors.pipe(
        //     mergeMap(this.retryOnInternalServerError),
        //     take(environment.httpRetryCount)
        //   );
        // }),
        catchError(err => {
          switch (err.status) {
            case 401:
              this.handleUnauthorized();
              break;
            default:
              this.checkIfUnauthorized();
              break;
          }

          return throwError(err);
        }));
  }

  private handleUnauthorized() {
    // auto logout if 401 response returned from api
    this.snackBar.open('登入失效，請重新登入。', '', {
      verticalPosition: 'top',
      horizontalPosition: 'right'
    });
    this.patSignIn.signOut().then(() => {
      this.router.navigateByUrl('')
        .then(() => {
          location.reload();
        });
    });
  }

  private checkIfUnauthorized() {
    if (!this.patSignIn.checkSignInStatus()) {
      this.handleUnauthorized();
    }
  }

  private retryOnInternalServerError(error: any) {
    const isFiltered = error
      && error.url
      && (error.url.includes('/api/tracking/track') || error.url.includes('/api/auth/') || error.url.includes('/api/ugc'));
    const timeout = environment.httpRetryTimeout;
    return error.status >= 500 && !isFiltered ? timer(timeout) : throwError(error);
  }
}
