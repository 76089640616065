import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'sign-in-statement-terms',
  templateUrl: './statement-terms.component.html',
  styleUrls: []
})
export class SignInStatementTermsComponent {

  constructor(private dialogRef: MatDialogRef<SignInStatementTermsComponent>) {
  }

  close() {
    this.dialogRef.close();
  }
}
