<ngx-json-ld [json]="schema"></ngx-json-ld>

<askpm-forum-nav (burgerClicked)="snav.toggle()"></askpm-forum-nav>

<mat-sidenav-container class="side-nav-container">
  <mat-sidenav class="side-nav"
               [mode]="'over'"
               [fixedInViewport]="true"
               #snav>
    <div class="media">
      <div class="media-body side-nav-drawer-body">
        <div class="font-pixel side-nav-header">
          上有高堂
        </div>
        <mat-action-list>
          <mat-list-item>
            <button class="btn no-focus align-items-center" routerLink="/" (click)="snav.toggle()">
              <i class="far fa-comment width-20 pr-5"></i>
              首頁
            </button>
          </mat-list-item>
        </mat-action-list>
        <mat-accordion>
          <mat-expansion-panel #profile>
            <mat-expansion-panel-header class="font-size-16" expandedHeight="48px" collapsedHeight="48px">
              <button class="btn no-focus align-items-center">
                <i class="far fa-user-circle width-20 pr-5"></i>
                個人檔案
              </button>
            </mat-expansion-panel-header>
            <div>
              <button class="btn no-focus align-items-center" routerLink="/profile" (click)="snav.toggle();profile.toggle();">
                <i class="far fa-address-card width-20 pr-5"></i>
                我的資訊
              </button>
            </div>
            <div>
              <button class="btn no-focus align-items-center" routerLink="/profile/bookmark" (click)="snav.toggle();profile.toggle();">
                <i class="far fa-bookmark width-20 pr-5"></i>
                我的收藏
              </button>
            </div>
            <mat-divider></mat-divider>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-action-list class="pt-0">
          <mat-list-item>
            <button class="btn no-focus align-items-center" routerLink="/help" (click)="snav.toggle()">
              <i class="fas fa-robot width-20 pr-5"></i>
              技術支援
            </button>
          </mat-list-item>
          <mat-list-item *ngIf="!isSignedIn">
            <button class="btn no-focus align-items-center" (click)="login()">
              <i class="fas fa-sign-out-alt width-20 pr-5"></i>
              登入
            </button>
          </mat-list-item>
          <mat-list-item *ngIf="isSignedIn">
            <button class="btn no-focus align-items-center" (click)="logout()">
              <i class="fas fa-sign-out-alt width-20 pr-5"></i>
              登出
            </button>
          </mat-list-item>
        </mat-action-list>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="bg-white">

    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
