/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./help-center.component";
var styles_HelpCenterComponent = [];
var RenderType_HelpCenterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HelpCenterComponent, data: {} });
export { RenderType_HelpCenterComponent as RenderType_HelpCenterComponent };
export function View_HelpCenterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "section", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "text-center mt-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u6280\u8853\u652F\u63F4"])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u5982\u9700\u6280\u8853\u652F\u63F4\uFF0C\u8ACB\u96FB\u90F5\u81F3"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [["href", "mailto:help@askpm.app"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["help@askpm.app"]))], null, null); }
export function View_HelpCenterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "askpm-help-center", [], null, null, null, View_HelpCenterComponent_0, RenderType_HelpCenterComponent)), i0.ɵdid(1, 49152, null, 0, i1.HelpCenterComponent, [], null, null)], null, null); }
var HelpCenterComponentNgFactory = i0.ɵccf("askpm-help-center", i1.HelpCenterComponent, View_HelpCenterComponent_Host_0, {}, {}, []);
export { HelpCenterComponentNgFactory as HelpCenterComponentNgFactory };
